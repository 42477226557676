import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

RHFMultiSelectOptions.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    defaultValue: PropTypes.array, // Ensure defaultValue is an array
};

export default function RHFMultiSelectOptions({ name, label, options, defaultValue = [], ...other }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    multiple
                    value={options.filter((option) => value.includes(option.value))}
                    onChange={(event, newValue) => {
                        const selectedValues = newValue.map((selectedOption) => selectedOption.value);
                        onChange(selectedValues);
                    }}
                    options={options}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            variant="outlined"
                            fullWidth
                            {...other}
                            error={!!errors[name]}
                            helperText={errors[name]?.message || ''}
                        />
                    )}
                />
            )}
        />
    );
}
