import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import roleAccessReducer from './slices/roleaccess';
import shopReducer from './slices/shop';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const productPersistConfig = {
    key: 'product',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const roleAccessPersistConfig = {
    key: 'roleaccess',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['rolepermissions', 'rolemodules'],
};

const rootReducer = combineReducers({
    mail: mailReducer,
    chat: chatReducer,
    shop: shopReducer,
    calendar: calendarReducer,
    kanban: kanbanReducer,
    product: persistReducer(productPersistConfig, productReducer),
    roleaccess: persistReducer(roleAccessPersistConfig, roleAccessReducer),
});

export { rootPersistConfig, rootReducer };
