import { lazy, Suspense } from 'react';
// import { useLocation } from 'react-router-dom';
import GuestGuard from '../guards/GuestGuard';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

/* eslint-disable arrow-body-style */
const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );
};

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

const LoginRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <GuestGuard>
                    <Login />
                </GuestGuard>
            ),
        },
        {
            path: '/auth/login',
            element: (
                <GuestGuard>
                    <Login />
                </GuestGuard>
            ),
        },
        {
            path: 'login',
            element: (
                <GuestGuard>
                    <Login />
                </GuestGuard>
            ),
        },
        {
            path: 'register',
            element: (
                <GuestGuard>
                    <Register />
                </GuestGuard>
            ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
    ],
};

export default LoginRoutes;
/* eslint-disable arrow-body-style */