import { lazy, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import VendorLayout from '../layouts/vendor/dashboard';
import AuthGuard from '../guards/AuthGuard';
import VendorGuard from '../guards/VendorGuard';
import { VENDOR_DASHBOARD } from '../config';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/vendor/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};
const Orders = Loadable(lazy(() => import('../pages/users/vendor/Order/Orders')));
const OrderOperation = Loadable(lazy(() => import('../pages/users/vendor/Order/OrderOperation')));
const Transactions = Loadable(lazy(() => import('../pages/users/vendor/transation/Transactions')));
const TransactionOpration = Loadable(lazy(() => import('../pages/users/vendor/transation/TransactionOperation')));
const Dashboard = Loadable(lazy(() => import('../pages/users/vendor/dashboard/Dashboard')));
const Users = Loadable(lazy(() => import('../pages/users/vendor/User/Users')));
const UserOperation = Loadable(lazy(() => import('../pages/users/vendor/User/UserOperation')));
const Shop = Loadable(lazy(() => import('../pages/users/vendor/shop/Shops')));
const ShopOperation = Loadable(lazy(() => import('../pages/users/vendor/shop/ShopOperation')));
const UserAccount = Loadable(lazy(() => import('../pages/profile/UserAccount')));
const Products = Loadable(lazy(() => import('../pages/users/vendor/products/Products')));
const ProductOperation = Loadable(lazy(() => import('../pages/users/vendor/products/ProductOperation')));
const Courses = Loadable(lazy(() => import('../pages/users/vendor/courses/Courses')));
const CourseOperation = Loadable(lazy(() => import('../pages/users/vendor/courses/CourseOperation')));
const CourseChapters = Loadable(lazy(() => import('../pages/users/vendor/courses/CourseChapters')));
const CourseChapterOperation = Loadable(lazy(() => import('../pages/users/vendor/courses/CourseChapterOperation')));
const Banners = Loadable(lazy(() => import('../pages/users/vendor/Banner/Banners')));
const BannerOperation = Loadable(lazy(() => import('../pages/users/vendor/Banner/BannerOperation')));
const Categories = Loadable(lazy(() => import('../pages/users/vendor/Category/Categories')));
const CategoryOperation = Loadable(lazy(() => import('../pages/users/vendor/Category/CategoryOperation')));
const Reviews = Loadable(lazy(() => import('../pages/users/vendor/Review/Reviews')));
const ReviewOperation = Loadable(lazy(() => import('../pages/users/vendor/Review/ReviewOperation')));
const Collections = Loadable(lazy(() => import('../pages/users/vendor/collection/Collections')));
const CollectionOperation = Loadable(lazy(() => import('../pages/users/vendor/collection/CollectionOperation')));
const CollectionItems = Loadable(lazy(() => import('../pages/users/vendor/collection/collectionItem/CollectionItems')));
const CollectionItemOperation = Loadable(lazy(() => import('../pages/users/vendor/collection/collectionItem/CollectionItemOperation')));

const Achievements = Loadable(lazy(() => import('../pages/users/vendor/Achievement/Achievements')));
const AchievementOperation = Loadable(lazy(() => import('../pages/users/vendor/Achievement/AchievementOperation')));


const Classes = Loadable(lazy(() => import('../pages/users/vendor/Class/Classes')));
const ClassOperation = Loadable(lazy(() => import('../pages/users/vendor/Class/ClassOperations')));
const VendorRoutes = {
    path: '/vendor',
    element: (
        <AuthGuard>
            <VendorGuard>
                <VendorLayout />
            </VendorGuard>
        </AuthGuard>
    ),
    children: [
        { element: <Navigate to={VENDOR_DASHBOARD} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        {
            path: 'profile',
            children: [{ path: 'edit-profile', element: <UserAccount /> }],
        },
        {
            path: 'user-management',
            children: [
                { path: 'delivery-boys', element: <Users /> },
                { path: 'delivery-boy/add', element: <UserOperation /> },
                { path: 'delivery-boy/:id/edit', element: <UserOperation /> },
                { path: 'delivery-boy/:id/view', element: <UserOperation /> },
            ],
        },
        {
            path: 'master',
            children: [
                { path: 'shops', element: <Shop /> },
                { path: 'shops/add', element: <ShopOperation /> },
                { path: 'shops/:id/edit', element: <ShopOperation /> },
                { path: 'shops/:id/view', element: <ShopOperation /> },
                { path: 'products', element: <Products /> },
                { path: 'product/add', element: <ProductOperation /> },
                { path: 'product/:id/edit', element: <ProductOperation /> },
                { path: 'product/:id/view', element: <ProductOperation /> },
                { path: 'courses', element: <Courses /> },
                { path: 'course/add', element: <CourseOperation /> },
                { path: 'course/:id/edit', element: <CourseOperation /> },
                { path: 'course/:id/view', element: <CourseOperation /> },
                { path: 'course/:id/chapters', element: <CourseChapters /> },
                { path: 'course/chapter/:id/add', element: <CourseChapterOperation /> },
                { path: 'course/:id/chapter/:Id/edit', element: <CourseChapterOperation /> },
                { path: 'course/:id/chapter/:Id/view', element: <CourseChapterOperation/> },
                { path: 'banners', element: <Banners /> },
                { path: 'banners/add', element: <BannerOperation /> },
                { path: 'banners/:id/edit', element: <BannerOperation /> },
                { path: 'banners/:id/view', element: <BannerOperation /> },
                { path: 'categories', element: <Categories /> },
                { path: 'categories/add', element: <CategoryOperation /> },
                { path: 'categories/:id/edit', element: <CategoryOperation /> },
                { path: 'categories/:id/view', element: <CategoryOperation /> },
                { path: 'orders', element: <Orders /> },
                { path: 'orders/add', element: <OrderOperation /> },
                { path: 'orders/:id/edit', element: <OrderOperation /> },
                { path: 'orders/:id/delete', element: <OrderOperation /> },
                { path: 'orders/:id/view', element: <OrderOperation /> },
                { path: 'transactions', element: <Transactions /> },
                { path: 'transactions/:id/view', element: <TransactionOpration /> },

                { path: 'reviews', element: <Reviews /> },
                { path: 'reviews/add', element: <ReviewOperation /> },
                { path: 'reviews/:id/edit', element: <ReviewOperation /> },
                { path: 'reviews/:id/view', element: <ReviewOperation /> },
                { path: 'collections', element: <Collections /> },
                { path: 'collections/add', element: <CollectionOperation /> },
                { path: 'collections/:id/edit', element: <CollectionOperation /> },
                { path: 'collections/:id/view', element: <CollectionOperation /> },
                { path: 'collections/:id/items', element: <CollectionItems /> },
                { path: 'collections/:id/items/add', element: <CollectionItemOperation /> },
                { path: 'collections/:id/items/:itemId/edit', element: <CollectionItemOperation /> },
                { path: 'collections/:id/items/:itemId/view', element: <CollectionItemOperation /> },

                { path: 'achievements', element: <Achievements /> },
                { path: 'achievements/add', element: <AchievementOperation /> },
                { path: 'achievements/:id/edit', element: <AchievementOperation /> },
                { path: 'achievements/:id/view', element: <AchievementOperation /> },
                

                { path: 'classes', element: <Classes /> },
                { path: 'classes/add', element: <ClassOperation /> },
                { path: 'classes/:id/edit', element: <ClassOperation /> },
                { path: 'classes/:id/view', element: <ClassOperation /> },

               
            ],
        },
    ],
};

export default VendorRoutes;
