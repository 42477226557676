import { useEffect } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import Settings from './components/settings';
import { useDispatch } from './redux/store';
import { GetRolePermissions, GetRoleModules } from './redux/slices/roleaccess';
import useAuth from './hooks/useAuth';


// ----------------------------------------------------------------------


export default function App() {
  const { user, isAuthenticated } = useAuth();
  const roleId = user?.Role?.id;
  // console.log('roleId', roleId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    dispatch(GetRolePermissions(roleId));
    dispatch(GetRoleModules());
  }
  , [dispatch, roleId, isAuthenticated]);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <Settings />
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
