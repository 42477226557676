/* eslint-disable arrow-body-style */
import { postMethod, postFormDataMethod } from '../apiConfig';

export const getShopsApi = (data) => {
    return postMethod('/shop/fetch-shops-with-pagination', data);
};

export const getShopApi = (data) => {
    return postMethod('/shop/fetch-shop-by-id', data);
};

export const createShopApi = (data) => {
    return postFormDataMethod('/shop/add', data);
};

export const updateShopApi = (data) => {
    return postFormDataMethod('/shop/update', data);
};

export const deleteShopApi = (data) => {
    return postMethod('/shop/delete', data);
};
