import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { user, isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isInitialized) {
      return <LoadingScreen />;
    }
    if (!isAuthenticated) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
      return;
    }
    
    if(isAuthenticated && user?.UserRole?.role === 'SuperAdmin') {
      if(pathname.includes('/super-admin')) {
        return <>{children}</>;
      }
      window.location.href = PATH_DASHBOARD.superAdminRoot;

    } 
    if(isAuthenticated && user?.UserRole?.role === 'Admin') {
      if(pathname.includes('/super-admin')) {
        return <>{children}</>;
      }
      window.location.href = PATH_DASHBOARD.superAdminRoot;
    }
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [user, isAuthenticated, isInitialized])


  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }
  
  return <>{children}</>;
}