import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
// eslint-disable-next-line no-unused-vars
import { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
// eslint-disable-next-line no-unused-vars
import { Box, Divider, Typography, Stack, MenuItem, FormControl, InputLabel, Select, Button } from '@mui/material';
// routes
import { PATH_AUTH } from '../../../../routes/paths';
// hooks
import useAuth from '../../../../hooks/useAuth';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import { ResetRoleAccess } from '../../../../redux/slices/roleaccess';
// components
import MyAvatar from '../../../../components/MyAvatar';
import MenuPopover from '../../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../../components/animate';
import { getShopsApi } from '../../../../apis/Shop/ShopApis';
import { setShops, setSelectedShop } from '../../../../redux/slices/shop';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Profile',
        linkTo: '/super-admin/profile/edit-profile',
    },
    // {
    //   label: 'Settings',
    //   linkTo: PATH_DASHBOARD.user.account,
    // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { shops, selectedShop } = useSelector((state) => state.shop);

    const { user, logout } = useAuth();

    const isMountedRef = useIsMountedRef();

    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const getShops = () => {
        getShopsApi()
            .then((res) => {
                const shopList = res?.data?.data?.rows;
                dispatch(setShops(shopList));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getShops();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleShopChange = (event) => {
        const selectedShopValue = event.target.value;
        if (selectedShopValue === '') {
            dispatch(setSelectedShop({ id: '', name: '' }));
        } else {
            const selectedShop = shops.find((shop) => shop.id === selectedShopValue);
            if (selectedShop) {
                dispatch(setSelectedShop({ id: selectedShop.id, name: selectedShop.shop_name }));
            }
        }
    };

    const handleLogout = async () => {
        try {
            await logout();
            dispatch(ResetRoleAccess());
            navigate(PATH_AUTH.login, { replace: true });

            if (isMountedRef.current) {
                handleClose();
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Unable to logout!', { variant: 'error' });
        }
    };

    return (
        <>
            <FormControl fullWidth>
                <InputLabel id="shop-select-label" shrink>
                    Shop
                </InputLabel>
                <Select
                    labelId="shop-select-label"
                    id="shop-select"
                    value={selectedShop.id}
                    label="Shop"
                    onChange={handleShopChange}
                    displayEmpty
                    renderValue={(selected) => {
                        if (!selected) {
                            return 'All Shops';
                        }
                        const shop = shops.find((shop) => shop.id === selected);
                        return shop ? shop.shop_name : 'All Shops';
                    }}
                    sx={{
                        minWidth: { xs: 250, md: 300 },
                        marginRight: 1,
                    }}
                >
                    <MenuItem value="">
                        <p>All Shops</p>
                    </MenuItem>
                    {shops.map((shop) => (
                        <MenuItem key={shop.id} value={shop.id}>
                            {shop.shop_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <MyAvatar />
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {`${user?.first_name} ${user?.last_name}`}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </MenuPopover>
        </>
    );
}
