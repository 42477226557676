/* eslint-disable arrow-body-style */
import { postFormDataMethod, postMethod } from '../apiConfig';

export const getProfileApi = (data) => {
    return postMethod('/profile/get-profile-details', data);
};

export const updateProfileApi = (data) => {
    return postFormDataMethod('/profile/update-profile-detail', data);
};

export const updatePasswordApi = (data) => {
    return postMethod('/profile/update-password', data);
};

/* eslint-disable arrow-body-style */
