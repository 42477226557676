import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, styled } from '@mui/material';

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputLabel-asterisk': {
        color: 'red',
    },
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)', // Ensures text color is not greyed out
    },
    '& .MuiInputBase-root.readOnly': {
        backgroundColor: '#f5f5f5', // Light background for read-only
        border: `1px solid ${theme.palette.grey[400]}`, // Border for distinction
    },
}));

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
};

export default function RHFTextField({ name, disabled, readOnly, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <StyledTextField
                    {...field}
                    noValidate
                    fullWidth
                    error={!!error}
                    disabled={disabled}
                    InputProps={{ readOnly }}
                    className={readOnly ? 'readOnly' : ''}
                    helperText={error?.message}
                    {...other}
                />
            )}
        />
    );
}
