import { postMethod } from '../apiConfig';

export const loginApi = (data) => {
    return postMethod('/auth/sign-in', data);
};

export const resetPasswordApi = (data) => {
    return postMethod('/auth/reset-password', data);
};

export const sendOTPApi = (data) => {
    return postMethod('/auth/send-otp', data);
};

export const checkOTPApi = (data) => {
    return postMethod('/auth/check-otp', data);
};
