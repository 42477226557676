// shopSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shops: [],
    selectedShop: { id: '', name: '' },
};

const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setShops(state, action) {
            state.shops = action.payload;
        },
        setSelectedShop(state, action) {
            state.selectedShop = action.payload;
        },
    },
});

export const { setShops, setSelectedShop } = shopSlice.actions;

export default shopSlice.reducer;
