import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const ContentStyle = styled(Card)(({ theme }) => ({
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px',
    // horizontally center the card

    margin: theme.spacing(6, 0, 2, 2),
    [theme.breakpoints.down('sm')]: {
        boxShadow: 'none',
        padding: 'unset',
        margin: 'unset',
    },
}));

// ----------------------------------------------------------------------

export default function Login() {
    const smUp = useResponsive('up', 'sm');

    // const mdUp = useResponsive('up', 'md');

    return (
        <Page title="Login">
            <RootStyle>
                <Container maxWidth="sm">
                    <ContentStyle>
                        {/* top left logo */}

                        <Box sx={{ mb: 5, display: 'flex', justifyContent: 'center' }}>
                            <RouterLink to="/">
                                <Logo />
                            </RouterLink>
                        </Box>

                        <Stack direction="row" alignItems="center" sx={{ mb: 5, mt:2 }}>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                                <Typography variant="h4" gutterBottom>
                                    Buty Public School Admin Panel
                                </Typography>
                            </Box>
                        </Stack>

                        <LoginForm />

                        {!smUp && (
                            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                                Don’t have an account?{' '}
                                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                                    Get started
                                </Link>
                            </Typography>
                        )}
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
}
